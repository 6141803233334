export const SET_APP_NAME = "GENERAL_SET_APP_NAME";
export const SET_AUTH_STATUS = "AUTH_SET_STATUS";
export const SET_USER_INFO = "AUTH_SET_USER_INFO";
export const AUTH_IS_FREE_TRIAL_APP = "AUTH_IS_FREE_TRIAL_APP";
export const IS_INVALID_TOKEN = "IS_INVALID_TOKEN";
export const SEND_ACTIVATION_EMAIL = "SEND_ACTIVATION_EMAIL";
export const IS_LOGIN_AUTOMATIC = "IS_LOGIN_AUTOMATIC";

export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_CHANGED = "GET_USER_CHANGED";
export const GET_USER_UPDATED = "GET_USER_UPDATED";
export const SET_CUSTOMERS_ERROR = "SET_CUSTOMERS_ERROR";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_DELETE = "SET_USER_DELETE";
export const SET_USER_ACTIVATED = "SET_USER_ACTIVATED";
export const SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const LOADING = "LOADING";
export const SET_USER_EXISTS_RESPONSE = "SET_USER_EXISTS_RESPONSE";
export const GET_USER_APPLICATIONS = "GET_USER_APPLICATIONS";

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_ALL_APPLICATIONS = "GET_ALL_APPLICATIONS";
export const GET_ALL_APPLICATIONS_MENU = "GET_ALL_APPLICATIONS_MENU";
export const GET_ALL_APPLICATIONS_NOTIFICATION = "GET_ALL_APPLICATIONS_NOTIFICATION";
export const APPLICATION_CREATED = "APPLICATION_CREATED";
export const APPLICATION_MODIFIED = "APPLICATION_MODIFIED";
export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const APPLICATION_ACTIVATED = "APPLICATION_ACTIVATED";

export const GET_PRIVILEGE = "GET_PRIVILEGE";
export const GET_PRIVILEGES = "GET_PRIVILEGES";
export const SET_PRIVILEGE_ERROR = "SET_PRIVILEGE_ERROR";
export const GET_PRIVILEGE_CHANGED = "GET_PRIVILEGE_CHANGED";

export const GET_INTEGRATION = "GET_INTEGRATION";
export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const SET_INTEGRATION_ERROR = "SET_INTEGRATION_ERROR";
export const GET_INTEGRATION_CHANGED = "GET_INTEGRATION_CHANGED";

export const GET_INTEGRATIONTYPE = "GET_INTEGRATIONTYPE";
export const GET_INTEGRATIONTYPES = "GET_INTEGRATIONTYPES";
export const SET_INTEGRATIONTYPE_ERROR = "SET_INTEGRATIONTYPE_ERROR";
export const GET_INTEGRATIONTYPE_CHANGED = "GET_INTEGRATIONTYPE_CHANGED";

export const GET_DATATYPE = "GET_DATATYPE";
export const GET_DATATYPES = "GET_DATATYPES";
export const SET_DATATYPE_ERROR = "SET_DATATYPE_ERROR";
export const GET_DATATYPE_CHANGED = "GET_DATATYPE_CHANGED";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICES = "GET_SERVICES";
export const SET_SERVICE_ERROR = "SET_SERVICE_ERROR";
export const GET_SERVICE_CHANGED = "GET_SERVICE_CHANGED";

export const GET_RESPONSECODE = "GET_RESPONSECODE";
export const GET_RESPONSECODES = "GET_RESPONSECODES";
export const SET_RESPONSECODE_ERROR = "SET_RESPONSECODE_ERROR";
export const GET_RESPONSECODE_CHANGED = "GET_RESPONSECODE_CHANGED";

export const GET_HOMOLOGATION = "GET_HOMOLOGATION";
export const GET_HOMOLOGATIONS = "GET_HOMOLOGATIONS";
export const SET_HOMOLOGATION_ERROR = "SET_HOMOLOGATION_ERROR";
export const GET_HOMOLOGATION_CHANGED = "GET_HOMOLOGATION_CHANGED";

export const GET_CUSTOMFORM = "GET_CUSTOMFORM";
export const GET_CUSTOMFORMS = "GET_CUSTOMFORMS";
export const SET_CUSTOMFORM_ERROR = "SET_CUSTOMFORM_ERROR";
export const GET_CUSTOMFORM_CHANGED = "GET_CUSTOMFORM_CHANGED";
export const GET_CUSTOMFORM_DELETED = "GET_CUSTOMFORM_DELETED";

export const GET_HOMOLOGATIONVALUE = "GET_HOMOLOGATIONVALUE";
export const GET_HOMOLOGATIONVALUES = "GET_HOMOLOGATIONVALUES";
export const SET_HOMOLOGATIONVALUE_ERROR = "SET_HOMOLOGATIONVALUE_ERROR";
export const GET_HOMOLOGATIONVALUE_CHANGED = "GET_HOMOLOGATIONVALUE_CHANGED";

export const GET_OBJECTTYPE = "GET_OBJECTTYPE";
export const GET_OBJECTTYPES = "GET_OBJECTTYPES";
export const SET_OBJECTTYPE_ERROR = "SET_OBJECTTYPE_ERROR";
export const GET_OBJECTTYPE_CHANGED = "GET_OBJECTTYPE_CHANGED";

export const GET_DOCUMENTTYPE = "GET_DOCUMENTTYPE";
export const GET_DOCUMENTTYPES = "GET_DOCUMENTTYPES";
export const SET_DOCUMENTTYPE_ERROR = "SET_DOCUMENTTYPE_ERROR";
export const GET_DOCUMENTTYPE_CHANGED = "GET_DOCUMENTTYPE_CHANGED";

export const GET_FORMATTYPES = "GET_FORMATTYPES";
export const GET_PROTOCOLTYPES = "GET_PROTOCOLTYPES";
export const GET_SERVICETYPES = "GET_SERVICETYPES";
export const GET_SERVICECATEGORIES = "GET_SERVICECATEGORIES";
export const GET_METHODTYPES = "GET_METHODTYPES";
export const GET_SECURITYTYPES = "GET_SECURITYTYPES";
export const GET_RESPONSECODETYPES = "GET_RESPONSECODETYPES";
export const GET_INTEGRATIONACTIONS = "GET_INTEGRATIONACTIONS";
export const GET_CUSTOMVIEWS = "GET_CUSTOMVIEWS";
export const GET_SIGNATURESUPPLIER = "GET_SIGNATURESUPPLIER"

export const GET_ENTERPRISE = "GET_ENTERPRISE";
export const GET_ENTERPRISEBYID = "GET_ENTERPRISEBYID";
export const GET_ENTERPRISES = "GET_ENTERPRISES";
export const SET_ENTERPRISE_ERROR = "SET_ENTERPRISE_ERROR";
export const GET_ENTERPRISE_CHANGED = "GET_ENTERPRISE_CHANGED";


export const GET_ROLE = "GET_ROLE";
export const GET_ROLES = "GET_ROLES";
export const SET_ROLE_ERROR = "SET_ROLE_ERROR";
export const GET_ROLE_CHANGED = "GET_ROLE_CHANGED";
export const GENERATE_TOKENS = "GENERATE_TOKENS";

export const REVOKE_TOKENS = "REVOKE_TOKENS";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_BY_USER_LIST_RESPONSE = "GET_MENU_BY_USER_LIST_RESPONSE";
export const GET_MENU_APP_LIST = "GET_MENU_APP_LIST";
export const GET_MENU_CHANGE = "GET_MENU_CHANGE";
export const GET_MENU_IMAGE_CHANGE = "GET_MENU_IMAGE_CHANGE";
export const GET_MENU_ERROR_CHANGE = "GET_MENU_ERROR_CHANGE";

export const GET_PRIVILEGES_AUTH = "GET_PRIVILEGES_AUTH";
export const GET_MESSAGE_REFRESH_TOKEN = "GET_MESSAGE_REFRESH_TOKEN";
export const IS_REFRESH_TOKEN = "IS_REFRESH_TOKEN";

export const GET_MENU_USER_RESPONSE = "GET_MENU_USER_RESPONSE";
export const GET_MENU_TOP_RESPONSE = "GET_MENU_TOP_RESPONSE";
export const GET_MENU_LEFT_RESPONSE = "GET_MENU_LEFT_RESPONSE";
export const GET_SUITE_MENU_RESPONSE = "GET_SUITE_MENU_RESPONSE";

export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const GET_ERROR_NOTIFICATION = "GET_ERROR_NOTIFICATION";
export const GET_ALL_SUBJECTS = "GET_ALL_SUBJECTS"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const CHANGE_NOTIFICATION = "CHANGE_NOTIFICATION"

export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS"
export const ERROR_GET_ALL_CLIENTS = "ERROR_GET_ALL_CLIENTS"
export const CHANGE_CLIENT_MESSAGE = "CHANGE_CLIENT_MESSAGE"
export const GET_ALL_SCOPES = "GET_ALL_SCOPES"
export const GET_ERROR_CHANGE_CLIENT = "GET_ERROR_CHANGE_CLIENT"
export const GET_ERROR_RESET_PASSWORD = "GET_ERROR_RESET_PASSWORD"
export const GET_RESPONSE_RESET_PASSWORD = "GET_RESPONSE_RESET_PASSWORD"

export const GET_AUTHENTICATION_TYPES = "GET_AUTHENTICATION_TYPES"

export const GET_ALL_DATA_MONITHOR = "GET_ALL_DATA_MONITHOR";
export const GET_ERROR_ALL_DATA_MONITHOR = "GET_ERROR_ALL_DATA_MONITHOR";
export const EXPORT_ALL_DATA = "EXPORT_ALL_DATA";
export const ERROR_EXPORT_ALL_DATA = "ERROR_EXPORT_ALL_DATA";
export const GET_RESPONSE_EXECUTE_RECORD = "GET_RESPONSE_EXECUTE_RECORD";
export const GET_ERROR_RESPONSE_EXECUTE_RECORD = "GET_ERROR_RESPONSE_EXECUTE_RECORD";
export const GET_RESPONSE_CHANGE_STATUS = "GET_RESPONSE_CHANGE_STATUS";
export const GET_ERROR_RESPONSE_CHANGE_STATUS = "GET_ERROR_RESPONSE_CHANGE_STATUS";
export const GET_COUNT_ALL_RECORDS = "GET_COUNT_ALL_RECORDS";
export const GET_PAYLOAD = "GET_PAYLOAD";
export const ERROR_GET_PAYLOAD = "ERROR_GET_PAYLOAD";
export const GET_RESPONSE_RETRY = "GET_RESPONSE_RETRY";
export const GET_ERROR_RESPONSE_RETRY = "GET_ERROR_RESPONSE_RETRY";
export const GET_TITLE = "GET_TITLE";
export const GET_OBJECT_TYPES = "GET_OBJECT_TYPES";

export const DEFAULTRESPONSE = {
  noResponseFromApi: "Error al realizar la petición.",
};
