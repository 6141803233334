//set app name to all project
export const APP_NAME = process.env.name || "MICROSERVICES";

//token prefix eg. 'Bearer ' or 'Token '
export const TOKEN_PREFIX = "Bearer ";

export const TIME_REFRESH_TOKEN = 20;

export const API_AUTHENTICATION =
  process.env.NODE_ENV === "production"
    ? "https://pif.parameta.co/authentication"
    : "https://pif.parameta.co/authentication";

export const API_SECURITY_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://pif.parameta.co/security"
    : "https://pif.parameta.co/security";
    //: "https://pif.test.parameta.co/security";

export const API_SAML_AUTHENTICATION =
  process.env.NODE_ENV === "production"
  ? "https://pifcore-test.parameta.co/sp"
  //: "https://localhost:443/sp";
  : "https://pifcore-test.parameta.co/sp";

  export const API_CRUD_DATA_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://pif-bios.parameta.co/api/cruddata"
    //: "http://localhost:10275/biosdata";
    : "https://pif-bios.parameta.co/api/cruddata";

export const APP_HEADER_NAME = "appName";

export const defaultResponses = {
  noResponseFromApi:
    "No hay respuesta por parte del servidor. Por favor intente de nuevo más tarde.",
};

export function defaultHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      //"access-control-allow-origin": "*",
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
}

export function defaultHeadersWithoutToken() {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      //"access-control-allow-origin": "*",
      //Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
}

export function defaultHeadersText() {
  return {
    headers: {
      "Content-Type": "text/plain",
      Accept: "application/json",
      //"access-control-allow-origin": "*",
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
}

export const encryptionKey = [
  227, 144, 75, 59, 108, 168, 38, 75, 193, 195, 63, 125, 144, 17, 12, 148, 217,
  49, 188, 190, 52, 189, 68, 134, 106, 170, 255, 88, 27, 183, 249, 88,
];

export const encryptionIv = [
  58, 237, 223, 159, 226, 61, 73, 88, 156, 25, 99, 11, 144, 81, 32, 101,
];

export const VERSION = "Versión 1.4.0";
