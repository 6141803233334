import {
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Launch, Search } from "@material-ui/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InputSearch from "../InputForm/InputSearch.component";
import SearchIcon from "@material-ui/icons/Search";
import MenuOrder from "../MenuOrder/menuOrder.component";
import IconEspecialButton from "../ButtonForm/IconEspecialButton.component";
import PropTypes from "prop-types";
import SwitchForm from "../Switch/switchForm.component";
import "./table.css";
import { findFinalOfWord } from "../../utils/proprietaryHooks";
import TrashIcon from "../IconsLibrary/TrashIcon";
import {CustomCheckbox} from "../CheckComponents/CustomCheckbox.component";
import { boolean } from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px 0 0 4px`,
      },
    },
    padding: '8px',
    '& .MuiTableCell-root': {
      padding: '6px'
    }
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  card: {
    borderRadius: "10px",
    background: theme.palette.primary.main,
  },
  paper: {
    width: "100% !important",
    padding: "10px 10px",
    background: theme.palette.common.white,
    boxShadow: "none"
  },
  table: {
    background: theme.palette.common.white,
    padding: "10px 10px 10px 10px",
    WebkitBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    MozBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    boxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
  },
  columnStyle: {
    fontSize: "14px",
    font: "normal normal 600 14px/16px Muli",
  },
  tableCell: {
    height: "26px",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.light, // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  alignSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  name: {
    color: "blue",
  },
  hover: {
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  labels: {
    font: "normal normal normal 14px/19px Muli",
    letterSpacing: "0px",
    color: "#575756",
    cursor: "pointer",
  },
  menuOrder: {
    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: "none",
      boxShadow: "none",
    },
  },
  row: {
    background: '#F6F6F6 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000033',
    opacity: 1,
    height: '45px',
  },
  colorSearchAdornment: {
    color: '#C6C6C6'
  }
}));

const CustomTable = ({
  data,
  paramToDisable,
  validateToDisable = true,
  isBoolTheValidationToDisable = true,
  columns,
  havePagination,
  buttons = {},
  mainParam,
  handleCellClick,
  option,
  setOption,
  globalDisabled,
  filteredDataHandle,
  customMaxLength,
  recordsCount,
  handleChangePageOrRows,
  pageOffset,
  pageSize,
  filtersText
}) => {
  const classes = useStyles();

  //Filtro para paginación
  let filtered = [];

  // manejo de estado
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isDropdownUp, setDropdownUp] = useState(false);
  const [page, setPage] = useState(pageOffset ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize ?? 10);
  const [search, setSearch] = useState(filtersText??"");
  const searchRef = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [orderedColumns, setOrderedColumns] = useState([]);
  let component;
  let config = {
    cellSize: {
      xs: 2
    }
  };

  const [currentFilter, setCurrentFilter] = useState({
    searchBar: true,
    option: false,
    dropdown: false,
  });

  const [lengthMax, setLengthMax] = useState(0);

  useEffect(() => {
    // mainSectionDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentData(data);
    if(data && data.length > lengthMax)
      setLengthMax(data.length);
  }, [data]);

  /*useEffect(() => {
    if(filteredDataHandle && search && lengthMax >= 5000){
      filteredDataHandle(search.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);*/

  // metodos de renderización de la tabla
  const getDropDownState = (id) => {
    const column = orderedColumns.find((item) => item.name === selectedColumn);
    const naturalOrder = !!column ? column.naturalOrder : false;
    if (selectedColumn === id && !naturalOrder) {
      return (
        <ArrowDropUp
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else if (selectedColumn === id && naturalOrder) {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    }
  };

  // metodos de renderización de la tabla
  const getCheckboxState = (id, row, opacity) => {
    if(row)
      row.controlId = id;
    return (
      <CustomCheckbox 
        id={id}
        name={id}
        opacity={opacity}
        onChangeHandler={(e, checked)=>{
          if(row)
            row.selected = checked;
          else{
            data.filter(item => !item.NotSelectionable).forEach(item => {
              item.selected = checked;
              document.getElementById(item.controlId).checked = checked;
              document.getElementById(item.controlId).value = checked;
            });
          }
        }}
      />
      );
  };

  const switchComponent = (value, row, column) => {
    const { component, haveIcon, handleIconClick } = column;
    const { handleChange } = component;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        spacing={2}
        justify={haveIcon ? "flex-end" : "center"}
        onMouseOver={() => {
          //if (Boolean(row[paramToDisable]) === false) {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = "visible";
            trashButton.style.cursor = "pointer";
          }
          //}
        }}
        onMouseLeave={() => {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = column.withDeleteIconAlwaysVisible ? "" : "hidden";
          }
        }}
      >
        {column.withStateIconByValue === undefined || (typeof column.withStateIconByValue === "function" ? column.withStateIconByValue(value) : false) ? (
        <Tooltip
          title={`Activar/Inactivar ${column.tooltipMessage}`}
          aria-label={`Activar/Inactivar ${column.name}`}
          className={classes.alignSwitch}
        >
          <SwitchForm
            checked={column.format(value)}
            name={column.tooltipMessage}
            disabled={globalDisabled || column.disabled}
            onChange={(e) => {
              handleChange(e, row.id, column.id, row);
              setCurrentFilter({
                searchBar: false,
                option: false,
                dropdown: false,
              });
            }}
          />
        </Tooltip>) : <></>}
        {column.withIcon || (typeof column.withDeleteIconByValue === "function" ? column.withDeleteIconByValue(value) : false) ? (
          <Tooltip
            title='Eliminar'
            aria-label="Eliminar"
            style={{ marginTop: 4, marginLeft: 2 }}
          // className={classes.alignSwitch}
          >
            <div>
            <TrashIcon
              id={row.id}
              style={{ visibility: column.withDeleteIconAlwaysVisible ? "" : "hidden", marginLeft: 4, justifyContent: 'center' }}
              color="#ff415a"
              width={'20px'}
              active={true}
              onClick={(evt) => {
                handleIconClick(evt, row.id);
              }}
            />
            </div>
          </Tooltip>
        ) : <></>}
      </Grid>
    );
  };

  const typographyComponent = (value, row, column) => {
    if (column.wordLength === undefined) column.key = undefined;
    const result = !!column.key
      ? findFinalOfWord(value ?? "", column.wordLength, column.key)
      : value;
    value = !!value ? value : "";
    let formatedResult = column.format ? column.format(typeof result === "boolean" ? result.toString() : result) : typeof result === "boolean" ? result.toString() : result;
    if(typeof formatedResult === "string"){
      formatedResult = formatedResult.trim();
    } else if(!formatedResult){
      formatedResult = "";
    }

    return (
      <>
        {column.wordLength && formatedResult.length > column.wordLength ? (
          <Tooltip title={value}>
            <Typography
              className={classes.labels}
              onClick={(evt) => {
                if (typeof handleCellClick === "function")
                  handleCellClick(evt, row, column);
              }}
              align={column.align ?? "left"}
              style={!!column.customClass ? column.customClass : null}
            >
              {formatedResult.substring(0, column.wordLength) + " ..."}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            className={classes.labels}
            onClick={(evt) => {
              if (typeof handleCellClick === "function")
                handleCellClick(evt, row, column);
            }}
            align={column.align ?? "left"}
            style={!!column.customClass ? column.customClass : null}
          >
            {formatedResult}
          </Typography>
        )}
      </>
    );
  };

  const getRowData = () => {
    if (currentFilter.searchBar) {
      filtered = filteredData;
    } else if (currentFilter.option) {
      filtered = orderByOption;
    } else if (currentFilter.dropdown) {
      filtered = orderedColumn;
    } else {
      filtered = currentData;
    }

    if (filtered.length === 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell>No se encontraron resultados</TableCell>
        </TableRow>
      );
    } else {
      const filterCounter = havePagination && !handleChangePageOrRows
        ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filtered;
      return filterCounter.map((row, key) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={key}
            className={
              paramToDisable !== undefined &&
                isBoolTheValidationToDisable ? Boolean(row[paramToDisable]) === validateToDisable ? "disabled_row"
                : globalDisabled
                  ? "disabled_row"
                  : "MuiTypography-root" : row[paramToDisable] === validateToDisable ? "disabled_row"
                : globalDisabled
                  ? "disabled_row"
                  : "MuiTypography-root"
            }
          >
            {columns.map((column) => {
              const value = column.id.split('.').length === 1 ? row[column.id]
                            : column.id.split('.').length === 2 ? row[column.id.split('.')[0]][column.id.split('.')[1]]
                            : column.id.split('.').length === 3 ? row[column.id.split('.')[0]][column.id.split('.')[1]][column.id.split('.')[2]]
                            : column.id.split('.').length === 4 ? row[column.id.split('.')[0]][column.id.split('.')[1]][column.id.split('.')[2]][column.id.split('.')[3]]
                            : "";
              return (
                <TableCell classes={{ root: classes.root }} key={column?.id} align={column?.align} size={"medium"}>
                    {stateSection(column, row, value, key)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
  };

  const stateSection = (column, row, value, key) => {
    if (!!column.children) {
      const children = column.children(value, row);
      const childrenHeader = column.childrenHeader !== undefined && !row.NotSelectionable ? getCheckboxState(`${column.id}-${key}`,row,1) : null;
      return (<div style={{display: "flex"}}>{childrenHeader}{children}</div>);
    }

    if (!!column.component) {
      if (!!column.isVisible) {
        if (column.isVisible(row)) {
          return switchComponent(value, row, column)
        } else {
          return null;
        }
      } else {
        return switchComponent(value, row, column)
      }
    }
    return typographyComponent(value, row, column);
  }

  const RecordNotFound = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={
            columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
          }
        />
        <TableCell>No se encontraron resultados</TableCell>
      </TableRow>
    );
  };

  // metodos de control de paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if(handleChangePageOrRows !== undefined && typeof handleChangePageOrRows === "function"){
      handleChangePageOrRows(newPage,rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if(handleChangePageOrRows !== undefined && typeof handleChangePageOrRows === "function"){
      handleChangePageOrRows(0,+event.target.value);
    }
  };

  const orderedColumn = useMemo(() => {
    const haveBeenOrdered = orderedColumns.find(
      (item) => item.name === selectedColumn
    );
    if (haveBeenOrdered !== undefined) {
      let orderedData = [];
      if (haveBeenOrdered.naturalOrder) {
        orderedData = data?.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        );
      } else {
        orderedData = data?.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] < b[selectedColumn]
              ? 1
              : -1
        );
      }
      return orderedData;
    } else {
      if (!!orderedColumns.length) {
        return data?.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        );
      }
    }
  }, [data, orderedColumns, selectedColumn]);

  const orderByDirection = (a, b, sortBy) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop] < b[sortBy[i].prop]
          ? -1
          : a[sortBy[i].prop] > b[sortBy[i].prop]
            ? 1
            : 0);
      i++;
    }
    return result;
  };

  const orderByOption = useMemo(() => {

    const { options } = !!buttons.menuOrder ? buttons.menuOrder : {};

    if (option !== null && option !== undefined) {
      for (const iter of options) {
        if (iter.label === options[option].label) {
          if (iter.columns.length === 1) {
            const { prop, direction } = iter.columns[0];
            if (direction === -1) {
              return currentData?.sort((a, b) =>
                a[prop] === b[prop] ? 0 : a[prop] > b[prop] ? 1 : -1
              );
            } else {
              return currentData?.sort((a, b) =>
                a[prop] === b[prop] ? 0 : a[prop] < b[prop] ? 1 : -1
              );
            }
          } else if (iter.columns.length > 1) {
            return currentData?.sort((a, b) =>
              orderByDirection(a, b, iter.columns)
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, option]);

  const filteredData = useMemo(() => {

    const queries = [];
    const hasAND = search.split("AND").length > 1;
    search.split("AND").forEach((searchAND)=>{
      const hasOR = searchAND.split("OR").length > 1;
      const lengthOR = searchAND.split("OR").length;
      searchAND.split("OR").forEach((searchOR, i)=>{
        if(searchOR.split("!=").length > 1){
          queries.push({
            fieldName: searchOR.split("!=")[0].trim().charAt(0).toLowerCase() + searchOR.split("!=")[0].trim().slice(1),
            fieldValue: searchOR.split("!=")[1].trim(),
            comparision: "1",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split("*=").length > 1){
          queries.push({
            fieldName: searchOR.split("*=")[0].trim().charAt(0).toLowerCase() + searchOR.split("*=")[0].trim().slice(1),
            fieldValue: searchOR.split("*=")[1].trim(),
            comparision: "6",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split(">=").length > 1){
          queries.push({
            fieldName: searchOR.split(">=")[0].trim().charAt(0).toLowerCase() + searchOR.split(">=")[0].trim().slice(1),
            fieldValue: searchOR.split(">=")[1].trim(),
            comparision: "3",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split("<=").length > 1){
          queries.push({
            fieldName: searchOR.split("<=")[0].trim().charAt(0).toLowerCase() + searchOR.split("<=")[0].trim().slice(1),
            fieldValue: searchOR.split("<=")[1].trim(),
            comparision: "5",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split("<").length > 1){
          queries.push({
            fieldName: searchOR.split("<")[0].trim().charAt(0).toLowerCase() + searchOR.split("<")[0].trim().slice(1),
            fieldValue: searchOR.split("<")[1].trim(),
            comparision: "4",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split(">").length > 1){
          queries.push({
            fieldName: searchOR.split(">")[0].trim().charAt(0).toLowerCase() + searchOR.split(">")[0].trim().slice(1),
            fieldValue: searchOR.split(">")[1].trim(),
            comparision: "2",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } else if(searchOR.split("=").length > 1){
          queries.push({
            fieldName: searchOR.split("=")[0].trim().charAt(0).toLowerCase() + searchOR.split("=")[0].trim().slice(1),
            fieldValue: searchOR.split("=")[1].trim(),
            comparision: "0",
            groupAction: hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "10",
            type: "string"
          });
        } 
      });
    });

    if(currentData && queries.length === 1){
      return currentData.filter((item)=> queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false
                                );
    } else if(currentData && queries.length === 2){
      return currentData.filter((item)=> 
                              (queries[0].groupAction === "10" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) &&
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : queries[0].groupAction === "9" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) ||
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : false)
                        );
    } else if(currentData && queries.length === 3){
      return currentData.filter((item)=> 
                            (queries[1].groupAction === "10" ?
                              (queries[0].groupAction === "10" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) &&
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : queries[0].groupAction === "9" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) ||
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : false) &&
                              (queries[2].comparision === "6" ? item[queries[2].fieldName]?.toString().toLowerCase().includes(queries[2].fieldValue.toLowerCase())
                              : queries[2].comparision === "1" ? item[queries[2].fieldName]?.toString().toLowerCase() !== queries[2].fieldValue.toLowerCase() 
                              : queries[2].comparision === "0" ? item[queries[2].fieldName]?.toString().toLowerCase() === queries[2].fieldValue.toLowerCase() 
                              : queries[2].comparision === "2" ? item[queries[2].fieldName] > queries[2].fieldValue
                              : queries[2].comparision === "3" ? item[queries[2].fieldName] >= queries[2].fieldValue
                              : queries[2].comparision === "4" ? item[queries[2].fieldName] < queries[2].fieldValue
                              : queries[2].comparision === "5" ? item[queries[2].fieldName] <= queries[2].fieldValue
                              : false)
                            : queries[1].groupAction === "9" ?
                              (queries[0].groupAction === "10" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) &&
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : queries[0].groupAction === "9" ? 
                                (queries[0].comparision === "6" ? item[queries[0].fieldName]?.toString().toLowerCase().includes(queries[0].fieldValue.toLowerCase())
                                : queries[0].comparision === "1" ? item[queries[0].fieldName]?.toString().toLowerCase() !== queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "0" ? item[queries[0].fieldName]?.toString().toLowerCase() === queries[0].fieldValue.toLowerCase() 
                                : queries[0].comparision === "2" ? item[queries[0].fieldName] > queries[0].fieldValue
                                : queries[0].comparision === "3" ? item[queries[0].fieldName] >= queries[0].fieldValue
                                : queries[0].comparision === "4" ? item[queries[0].fieldName] < queries[0].fieldValue
                                : queries[0].comparision === "5" ? item[queries[0].fieldName] <= queries[0].fieldValue
                                : false) ||
                                (queries[1].comparision === "6" ? item[queries[1].fieldName]?.toString().toLowerCase().includes(queries[1].fieldValue.toLowerCase())
                                : queries[1].comparision === "1" ? item[queries[1].fieldName]?.toString().toLowerCase() !== queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "0" ? item[queries[1].fieldName]?.toString().toLowerCase() === queries[1].fieldValue.toLowerCase() 
                                : queries[1].comparision === "2" ? item[queries[1].fieldName] > queries[1].fieldValue
                                : queries[1].comparision === "3" ? item[queries[1].fieldName] >= queries[1].fieldValue
                                : queries[1].comparision === "4" ? item[queries[1].fieldName] < queries[1].fieldValue
                                : queries[1].comparision === "5" ? item[queries[1].fieldName] <= queries[1].fieldValue
                                : false)
                              : false) ||
                              (queries[2].comparision === "6" ? item[queries[2].fieldName]?.toString().toLowerCase().includes(queries[2].fieldValue.toLowerCase())
                              : queries[2].comparision === "1" ? item[queries[2].fieldName]?.toString().toLowerCase() !== queries[2].fieldValue.toLowerCase() 
                              : queries[2].comparision === "0" ? item[queries[2].fieldName]?.toString().toLowerCase() === queries[2].fieldValue.toLowerCase() 
                              : queries[2].comparision === "2" ? item[queries[2].fieldName] > queries[2].fieldValue
                              : queries[2].comparision === "3" ? item[queries[2].fieldName] >= queries[2].fieldValue
                              : queries[2].comparision === "4" ? item[queries[2].fieldName] < queries[2].fieldValue
                              : queries[2].comparision === "5" ? item[queries[2].fieldName] <= queries[2].fieldValue
                              : false)
                            : false)
                        );
    } else if(currentData){
      return currentData.filter((item) => {
        for (const key in item) {
          if (
            item[key] !== undefined &&
            item[key] !== null &&
            (typeof item[key] === "string" || typeof item[key] === "number")
          ) {
            const filter =
              typeof item[key] === "string" ? item[key].toLowerCase() 
              : typeof item[key] === "number" ? item[key].toString().toLowerCase()
              : item[key];
            if (filter.includes(search.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
    }
  }, [currentData, search]);

  if (!!buttons.children) {
    if (!!buttons.children().component) {
      component = buttons.children().component;
    } else {
      component = buttons.children;
    }
    if (!!buttons.children().config) {
      config = buttons.children().config;
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center" direction="row" spacing={0} justifyContent={!!buttons.justify ? buttons.justify : 'flex-start'}>
        {buttons.searchBar && (
          <Grid item 
            lg={buttons.searchBar.cellSize.lg} 
            md={buttons.searchBar.cellSize.md} 
            sm={buttons.searchBar.cellSize.sm}
            xs={buttons.searchBar.cellSize.xs}
            >
            <InputSearch
              name="CustomersFilter"
              value={search}
              inputRef={searchRef}
              disabled={globalDisabled || buttons.searchBar.disabled}
              onChange={(e) => {
                setSearch(searchRef.current.value);
                setPage(0);
                setCurrentFilter({
                  searchBar: true,
                  option: false,
                  dropdown: false,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if(filteredDataHandle && lengthMax >= (customMaxLength ?? 5000)){
                    filteredDataHandle(search);
                  }
                }
              }}
              onFocus={() => {
                setEditing(true);
              }}
              onBlur={() => {
                setEditing(false);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.colorSearchAdornment} />
                  </InputAdornment>
                ),
                // endAdornment: (
                //     <InputAdornment position="end"  >
                //         <ArrowDropDown color="secondary" />
                //     </InputAdornment>
                // )
              }}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        )}
        {/* {(buttons.menuOrder || buttons.exportButton) ?
          <Grid container item lg={3} md={3} sm={2} justify={!!buttons.menuOrder.justify ? buttons.menuOrder.justify : 'flex-start'} spacing={2} > */}
            {buttons.menuOrder && (
              <Grid item container
                lg={buttons.menuOrder.cellSize.lg} 
                md={buttons.menuOrder.cellSize.md}
                sm={buttons.menuOrder.cellSize.sm} 
                xs={buttons.menuOrder.cellSize.xs} 
                style={{ alignSelf: "center", flexFlow: "row", paddingLeft: "0px"}}
                >
                {buttons.searchButton && (
                  <IconEspecialButton
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      size={"small"}
                      onClick={(e) => {
                          if(filteredDataHandle){
                            filteredDataHandle(search);
                          }
                        }
                      }
                      startIcon={<Search />}
                    >
                      Buscar
                  </IconEspecialButton>
                )}
                <MenuOrder
                  size="small"
                  disabled={globalDisabled}
                  options={
                    !!buttons.menuOrder
                      ? buttons.menuOrder.options.map((item) => item.label)
                      : []
                  }
                  selectItem={(opt) => {
                    setOption(opt);
                    setPage(0);
                    setSearch("");
                    setCurrentFilter({
                      searchBar: false,
                      option: true,
                      dropdown: false,
                    });
                  }}
                />
              </Grid>
            )}
            {buttons.exportButton && (
              <Grid 
                item 
                lg={buttons.exportButton.cellSize.lg}
                md={buttons.exportButton.cellSize.md}
                sm={buttons.exportButton.cellSize.sm}
                xs={buttons.exportButton.cellSize.xs}
                >
                <IconEspecialButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  size={"small"}
                  onClick={null}
                  disabled={globalDisabled}
                  startIcon={<Launch />}
                >
                  Exportar
                </IconEspecialButton>
              </Grid>
            )}
          {/* </Grid> : null
        } */}
        {!!component && (
          <Grid
            container
            item
            lg={config.cellSize.lg}
            md={config.cellSize.md}
            sm={config.cellSize.sm}
            xs={config.cellSize.xs}
            direction="row"
            alignItems="center"
            justify={!!config.justify ? config.justify : "flex-end"}
            spacing={config.spacing} 
          >
            {component()}
          </Grid>
        )}
      </Grid>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="custom table"
          size="small"
          id='customTableScroll'
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCell}
                  onClick={(evt) => {
                    if (column.haveDropDown) {
                      setSelectedColumn(column.id);
                      const haveBeenOrdered = orderedColumns.find(
                        (item) => item.name === column.id
                      );
                      if (haveBeenOrdered !== undefined) {
                        setOrderedColumns([
                          ...orderedColumns.map((item) =>
                            item === haveBeenOrdered
                              ? {
                                name: haveBeenOrdered.name,
                                naturalOrder: !haveBeenOrdered.naturalOrder,
                              }
                              : item
                          ),
                        ]);
                      } else {
                        setOrderedColumns([
                          ...orderedColumns,
                          { name: column.id, naturalOrder: true },
                        ]);
                      }
                      setCurrentFilter({
                        searchBar: false,
                        option: false,
                        dropdown: true,
                      });
                    }
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent={column.alignHeader ?? "left"}
                    alignItems="center"
                  >
                    <div style={{display: "flex", width: "100%"}}>
                      {column.childrenHeader !== undefined && getCheckboxState(column.id)}
                      {column.childrenHeader !== undefined && column.childrenHeader()}
                    </div>
                    <Typography className={classes.columnStyle} >
                      <Tooltip title={column.id.charAt(0).toUpperCase() + column.id.slice(1)} arrow>
                        <label>{column.label}</label>
                      </Tooltip>
                    </Typography>
                    <Typography className={classes.columnStyle}>
                      {column.haveDropDown ? getDropDownState(column.id) : null}
                    </Typography>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEditing
              ? currentData?.length > 0
                ? getRowData(currentData)
                : RecordNotFound()
              : currentData?.length > 0
                ? getRowData(currentData)
                : RecordNotFound()}
          </TableBody>
        </Table>
      </TableContainer>
      {havePagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
          count={recordsCount ?? filtered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count===-1?"[+5000]":count}`
          }
          labelRowsPerPage="Filas por página"
          nextIconButtonText="Siguiente"
          backIconButtonText="Anterior"
        />
      ) : null}
    </Paper>
  );
};

CustomTable.propTypes = {
  data: PropTypes.array,
  paramToDisable: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      wordLength: PropTypes.number,
      label: PropTypes.string,
      align: PropTypes.string,
      haveDropDown: PropTypes.bool,
      format: PropTypes.func,
      children: PropTypes.func,
      tooltipMessage: PropTypes.string,
      withIcon: PropTypes.bool,
      handleIconClick: PropTypes.func,
      component: PropTypes.shape({
        handleChange: PropTypes.func,
      }),
    })
  ),
  havePagination: PropTypes.bool,
  buttons: PropTypes.shape({
    menuOrder: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              prop: PropTypes.string,
              direction: PropTypes.number,
            })
          ),
        })
      ),
    }),
    children: PropTypes.elementType,
  }),
  handleCellClick: PropTypes.func,
};

export default CustomTable;
