import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px", backgroundColor: "#ffe6e6 !important"
  },
  opposite: {
    flex: "none"
  },
  oppositeTime: {
    fontSize: "10px"
  },
  errorTail: {
    backgroundColor: theme.palette.error.main
  },
  successTail: {
    backgroundColor: theme.palette.success.main
  }
}));

export default function TransactionTimeline() {
  const [isOpen, setIsOpen] = useState(false);
  const [number, setNumber] = useState(1);
  const classes = useStyles();

  const toggle = (numero) => () => {
    setIsOpen(!isOpen);
    setNumber(numero);
    return null;
  };

  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite}>
          <Typography variant="body2">
            Coupa
          </Typography>
          <Typography className={classes.oppositeTime}>
            2023-04-20<br/>09:52 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="grey" >
            <MiscellaneousServicesIcon htmlColor="green" onClick={toggle(1)} />
          </TimelineDot>
          <TimelineConnector className={classes.successTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Accordion
            expanded={isOpen && number===1}
            style={isOpen && number===1? { border: "1px solid black" } : {}}
          >
            <AccordionSummary onClick={toggle(1)}>
              <Typography>
                Proveedor
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Transacción exitosa
              </Typography>
            </AccordionDetails>
          </Accordion>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite}>
          <Typography variant="body2" >
            PIF
          </Typography>
          <Typography className={classes.oppositeTime}>
            2023-04-20<br/>09:52 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="grey" variant="outlined">
            <MiscellaneousServicesIcon htmlColor="green" onClick={toggle(2)} />
          </TimelineDot>
          <TimelineConnector className={classes.successTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Accordion
            expanded={isOpen && number===2}
            style={isOpen && number===2? { border: "2px solid black" } : {}}
          >
            <AccordionSummary onClick={toggle(2)}>
              <Typography>
                Recepción
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Transacción exitosa
              </Typography>
            </AccordionDetails>
          </Accordion>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite}>
          <Typography variant="body2" >
            PIF
          </Typography>
          <Typography className={classes.oppositeTime}>
            2023-04-20<br/>09:52 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="grey" variant="outlined">
            <MiscellaneousServicesIcon htmlColor="green" onClick={toggle(3)} />
          </TimelineDot>
          <TimelineConnector className={classes.successTail} />
        </TimelineSeparator>
        <TimelineContent>
          <Accordion
            expanded={isOpen && number===3}
            style={isOpen && number===3? { border: "2px solid black" } : {}}
          >
            <AccordionSummary onClick={toggle(3)}>
              <Typography>
                Transformación
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Transacción exitosa
              </Typography>
            </AccordionDetails>
          </Accordion>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite}>
          <Typography variant="body2" >
            PIF
          </Typography>
          <Typography className={classes.oppositeTime}>
            2023-04-20<br/>09:53 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="grey" variant="outlined">
            <MiscellaneousServicesIcon htmlColor="green" onClick={toggle(4)}/>
          </TimelineDot>
          <TimelineConnector className={classes.errorTail} /> 
        </TimelineSeparator>
        <TimelineContent>
        <Accordion
            expanded={isOpen && number===4}
            style={isOpen && number===4? { border: "2px solid black" } : {}}
          >
            <AccordionSummary onClick={toggle(4)}>
              <Typography>
                Exportación
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Transacción exitosa
              </Typography>
            </AccordionDetails>
          </Accordion>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.opposite}>
          <Typography variant="body2" >
            NetSuite
          </Typography>
          <Typography className={classes.oppositeTime}>
            2023-04-20<br/>09:53 am
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <MiscellaneousServicesIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={0} className={classes.paper}>
            <Typography>
              Creación NetSuite
            </Typography>
            <Typography>
              Error
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}