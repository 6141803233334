import { Divider, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { executeRecord } from "../../actions/monithor.action";
import { getResponseCodesByCodeAndCustomer } from "../../actions/responsecode.action";
import FormLayout from "../../components/layouts/FormLayout";
import { jsonStringify, jsonParse } from "../../utils/utils";
import CopyIcon from "@mui/icons-material/ContentCopyOutlined";
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from "@mui/material/SvgIcon";
import { GET_PAYLOAD } from "../../actions/types";
import TransactionTimeline from "../../components/TimeLine/transactionTimeLine.component";

export const MonitoringDetailView = (props) => {
  const { setCurrentRecord, setShowDetail, currentRecord, enterprise } = props;

  const [labels] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [jsonData, setJsonData] = useState("");
  const [knowledgeBaseURL, setKnowledgeBaseURL] = useState("#");

  const backButton = () => {
    setCurrentRecord({});
    setShowDetail(false);
  };

  const { getPayload } = useSelector((store) => store.monithorReducer);
  const { getResponseCodesResponse } = useSelector((store) => store.responseCodesReducer);
  const dispatch = useDispatch();
  const translatedLabels = [
    { id:"object_id", valueES:"ID Objecto", valueEN:"Object ID", valuePR:"", order: 10, visible: true},
    { id:"initial_attempt_time", valueES:"Creación", valueEN:"Creation", valuePR:"", order: 15, visible: true},
    { id:"date_create", valueES:"Creación", valueEN:"Creation", valuePR:"", order: 16, visible: true},
    { id:"last_updated_time", valueES:"Última ejecución", valueEN:"Last excecution", valuePR:"", order: 20, visible: true},
    { id:"last_execution_date", valueES:"Última ejecución", valueEN:"Last excecution", valuePR:"", order: 21, visible: true},
    { id:"id_coupa", valueES:"ID Coupa", valueEN:"Coupa ID", valuePR:"", order: 30, visible: true},
    { id:"id_sap", valueES:"ID SAP", valueEN:"SAP ID", valuePR:"", order: 40, visible: true},
    { id:"company_name", valueES:"Compañía", valueEN:"Enterprise", valuePR:"", order: 45, visible: true},
    { id:"country", valueES:"País", valueEN:"Country", valuePR:"", order: 46, visible: true},
    { id:"subject", valueES:"Sujeto", valueEN:"Subject", valuePR:"", order: 47, visible: true},
    { id:"email", valueES:"Correo", valueEN:"Mail", valuePR:"", order: 48, visible: true},
    { id:"id", valueES:"ID", valueEN:"ID", valuePR:"ID", order: 50, visible: true},
    { id:"process_id", valueES:"ID de proceso", valueEN:"Process ID", valuePR:"", order: 60, visible: true},
    { id:"correlation_id", valueES:"ID Correlación", valueEN:"Correlation ID", valuePR:"", order: 63, visible: true},
    { id:"event_id", valueES:"ID evento", valueEN:"Event ID", valuePR:"", order: 66, visible: true},
    { id:"status_id", valueES:"ID Estado", valueEN:"Status ID", valuePR:"", order: 70, visible: true},
    { id:"status_name", valueES:"Estado", valueEN:"Status", valuePR:"", order: 80, visible: true},
    { id:"status", valueES:"Estado", valueEN:"Status", valuePR:"", order: 81, visible: true},
    { id:"name", valueES:"Nombre", valueEN:"Name", valuePR:"", order: 85, visible: true},
    { id:"description", valueES:"Descripción", valueEN:"Description", valuePR:"", order: 90, visible: true},
    { id:"message", valueES:"Mensaje", valueEN:"Message", valuePR:"", order: 95, visible: true},
    { id:"type_message", valueES:"Tipo de mensaje", valueEN:"Message type", valuePR:"", order: 96, visible: true},
    { id:"code_error_http", valueES:"Código (http)", valueEN:"Code (http)", valuePR:"", order: 100, visible: true},
    { id:"payload", valueES:"Payload", valueEN:"Payload", valuePR:"", order: 110, visible: true},
    { id:"payload_type", valueES:"Tipo de payload", valueEN:"Payload type", valuePR:"", order: 120, visible: true},
    { id:"attempt_counter", valueES:"Intentos", valueEN:"Attempt", valuePR:"", order: 130, visible: true},
    { id:"code", valueES:"Código", valueEN:"Code", valuePR:"", order: 135, visible: true},
    { id:"transaction_id", valueES:"ID Transacción", valueEN:"Transaction ID", valuePR:"", order: 200, visible: false},
    { id:"task_type", valueES:"Integración", valueEN:"Integration", valuePR:"", order: 210, visible: false},
    { id:"object_type", valueES:"Integración", valueEN:"Integration", valuePR:"", order: 220, visible: false},
    { id:"url_origen", valueES:"URL Origen", valueEN:"Source URL", valuePR:"", order: 230, visible: false}
  ];
  
  useEffect(() => {
    let order = 170;
    Object.keys(currentRecord).filter(item=>item !== "NotSelectionable" && item !== "controlId" && !item.includes("URL")).forEach((item)=>{
      let exists = false;
      translatedLabels.filter(lb=>lb.id.toLowerCase() === item.toLowerCase()).forEach((lb)=>{
        exists = true;
        labels.push({
          id: lb.valueES,
          value: currentRecord[item],
          order: lb.order,
          visible: lb.visible,
          collapsed: true
        });
      });
      if(!exists){
        labels.push({
          id: item,
          value: currentRecord[item],
          order: order,
          visible: true,
          collapsed: true
        });
        order++;
      }
    });

    dispatch({
      type: GET_PAYLOAD,
      payload: "",
    });

    const data = {
      version: 1,
      OBJECT_ID: currentRecord.OBJECT_ID,
      TASK_TYPE: currentRecord.TASK_TYPE,
      ACTION: "PAYLOAD"
    };
    if(!(currentRecord.URL_PAYLOAD === undefined || currentRecord.URL_PAYLOAD === null || (typeof currentRecord.URL_PAYLOAD === 'string' && currentRecord.URL_PAYLOAD.trim().length === 0))){
      dispatch(executeRecord(data, true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(getPayload){
      setJsonData(jsonStringify(jsonParse(getPayload), null, 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPayload]);

  useEffect(() => {
    if(enterprise && (currentRecord.code || currentRecord.CODE)){
      dispatch(getResponseCodesByCodeAndCustomer(enterprise.id,currentRecord.code ?? currentRecord.CODE));
    }
  }, [enterprise]);

  useEffect(() => {
    if (getResponseCodesResponse) {
      setKnowledgeBaseURL(getResponseCodesResponse.length ? getResponseCodesResponse[0].knowledgeBaseURL : "#");
    }
  }, [getResponseCodesResponse]);

  return (
    <div>
      
    <FormLayout
      subheaderBase={"Integración"}
      subheader={currentRecord.TASK_TYPE??currentRecord.object_type}
      handleIconClick={backButton}
      isEditing={false}
      isPrivilege={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography color="primary" style={{marginBottom: "15px"}} variant="h6">
            Transacción: {currentRecord.ID??currentRecord.transaction_id}
          </Typography>
        </Grid>
        {/* {!currentRecord.transaction_id &&
        (<Grid item style={{paddingLeft: 0, minWidth: 335}}>
            <TransactionTimeline  />
        </Grid>)
        } 
        <Grid container lg={currentRecord.transaction_id ? 10: 7} xs={12}> */}
        <Grid container xs={12}>
          {/* <Divider style={{ height: "1px", width: "100%" }} /> */}
          {labels.filter(lb=>lb.visible).sort((a,b)=>{return a.order===b.order?0:a.order<b.order?-1:1;}).map((lb) => {
            let value = lb.value?.toString()??"";
            return (
              <>
                <Grid item md={1} sm={12} xs={12} style={{minWidth: 200, maxWidth: 200}} >
                  <Typography style={{fontWeight: 700, margin: "2px"}}>{lb.id}</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12} style={{margin: "2px", display: "flex"}} >
                  <Typography style={{margin: "2px"}}>
                    {lb.id === "Código"? (<a href="#"
                      onClick={(e)=>{
                        window.open(knowledgeBaseURL);
                      }}
                    >{value}</a>) : lb.collapsed ? (value.length ? value : "-").substring(0,500) : value}
                    {value.length > 500 && (
                      <a href="#" 
                      onClick={(e)=>{
                        lb.collapsed = !lb.collapsed
                      }}>{lb.collapsed ? "... más" : "... menos"}</a>
                    )}
                  </Typography>
                  {value?.length > 500 && (
                    <Tooltip title="Copiar">
                      <SvgIcon style={{cursor:"pointer"}} component={CopyIcon} onClick={(ev)=>{
                          navigator.clipboard.writeText(value);
                        }}/>
                    </Tooltip>
                  )}
                </Grid>
                <Divider style={{ height: "1px", width: "100%" }} />
              </>
            );
          })}
          {/* <Grid>
            <Typography color="primary" className={{fontWeight: 600}} variant="h6">
              <a href="#" 
                onClick={(e)=>{
                  window.open("https://knowledge.parameta.co/?epkb_post_type_1=exportacion-de-facturas");
                }}>Cod: 2030</a>
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
      <br></br>
      {(!(currentRecord.URL_PAYLOAD === undefined || currentRecord.URL_PAYLOAD === null || (typeof currentRecord.URL_PAYLOAD === 'string' && currentRecord.URL_PAYLOAD.trim().length === 0))) && 
        <>
        <br></br>
        <Grid container>
          <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
            Más detalles
          </Typography>
        </Grid>
        <br></br>
        <Grid container>
          {getPayload ? (
            <div style={{display: "flex"}}>
              <Typography>
                {collapsed ? jsonData?.substring(0,500) : jsonData}
                {jsonData?.length > 500 && (
                  <a href="#" 
                  onClick={(e)=>{
                    setCollapsed(!collapsed);
                  }}>{collapsed ? "... más" : "... menos"}</a>
                )}
              </Typography>
              {jsonData?.length > 500 && (
                <Tooltip title="Copiar">
                  <SvgIcon style={{cursor:"pointer"}} component={CopyIcon} onClick={(ev)=>{
                      navigator.clipboard.writeText(jsonData);
                    }}/>
                </Tooltip>
              )}
            </div>
          ) : (
            <Typography>Cargando ...</Typography>
          )}
        </Grid>
        <br></br>
        </>
      }
    </FormLayout>
    </div>
  );
};
