import React, { useEffect, useRef, useState } from "react";
import CustomTable from "../../components/Table/CustomTable.component";
import ViewLayout from "../../components/layouts/ViewLayout";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  makeStyles,
  InputAdornment,
  TablePagination,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  executeRecord,
  getAllRecords,
  exportAllRecords,
  updateStatus,
  getCountAllRecordsAction,
  getObjectTypeAction,
  setUrlMonithorService,
} from "../../actions/monithor.action";
import { MonitoringDetailView } from "./monitoringDetail.view";
import { FullLoader, useDialog } from "@pif-tr-components/components";
import { Search, Launch } from "@material-ui/icons";
import { 
    GET_ERROR_RESPONSE_RETRY, 
    GET_RESPONSE_RETRY, 
    EXPORT_ALL_DATA, 
    ERROR_EXPORT_ALL_DATA, 
    GET_ALL_DATA_MONITHOR, 
    GET_ERROR_ALL_DATA_MONITHOR,
    GET_COUNT_ALL_RECORDS, 
    GET_RESPONSE_CHANGE_STATUS, 
    GET_ERROR_RESPONSE_CHANGE_STATUS 
} from "../../actions/types";
import InputSearch from "../../components/InputForm/InputSearch.component";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import SvgIcon from "@mui/material/SvgIcon";
import SkipIcon from "@mui/icons-material/ExitToApp";
import RetryIcon from "@mui/icons-material/RestartAlt";
import IconEspecialButton from "../../components/ButtonForm/IconEspecialButton.component";
import {CustomCheckbox} from "../../components/CheckComponents/CustomCheckbox.component";
import { isEmpty } from "../../utils/proprietaryHooks";

const action = {
  RETRY: "retry",
  CHANGE_STATE: "Change_State",
  VIEW_DETAIL: "View_detail",
  SELECTED_RETRY: "selected_retry",
  SELECTED_CHANGE_STATE: "Selected_Change_State"
};

const useStyles = makeStyles((theme) => ({
  img: {
    width: "25px",
  },
  paddingButton: {
    padding: "5px",
  },
  Icon: {
    cursor:"pointer",
    float: "right",
    color: '#575756',
    "&:hover": {
      color: '#da163d'
    }
  },
  IconHeader: {
    cursor:"pointer",
    float: "right",
    color: 'white',
    "&:hover": {
      color: 'darkred'
    }
  }
}));

export const MonitoringView = ({ component: Component, container, ...props }) => {
  let params = new URLSearchParams(window.location.search);
  
  const { Loading, user } = props;
  const classes = useStyles();

  const [option, setOption] = useState(null);
  const [objectTypeParam, setObjectTypeParam] = useState(params.get("objectType") ? `objectType=${params.get("objectType")}`: "");
  const [environmentURL, setEnvironmentURL] = useState(params.get("env") ? `https://${params.get("env")}.parameta.co` : "");
  const [fullURL] = useState(params.get("fullurl") ? params.get("fullurl") : "");
  const [version, setVersion] = useState(parseInt(params.get("ver") ?? "1"));
  const [customEmail, setCustomEmail] = useState(false);
  const [datatable, setDatatable] = useState([]);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const dispatch = useDispatch();
  const [messageAlert] = useState({
    messageAlert:"Acción realizada correctamente",
    confirmText:"Aceptar"
  });
  let [isLoading, setIsLoading] = useState(false);

  const setLoading = (value)=>{
    setIsLoading(value);
    if(Loading !== undefined){
      setIsLoading(value);
    }
  }

  const { Dialog, onOpen, onClose } = useDialog({
    bodyText: messageAlert.messageAlert,
    confirmationButtonText: messageAlert.confirmText,
    cancelButtonText: messageAlert.cancelText,
    onConfirmClick: messageAlert.handlerConfirm
  });

  const informMessage = (msg) => {
    messageAlert.messageAlert = msg;
    messageAlert.cancelText = null;
    messageAlert.handlerConfirm = null
  };
  const confirmMessage = (msg, actionCurrent, value) => {
    messageAlert.messageAlert = `¿Esta seguro de realizar la acción "${msg}"?`;
    messageAlert.cancelText = "Cancelar";
    messageAlert.handlerConfirm = () => {
      handleClickButton(actionCurrent, value);
      onClose();
    };
    onOpen();
  };

  const searchRef = useRef(null);

  const {
    getAllData,
    getErrorAllData,
    getResponseRetry,
    getErrorResponseRetry,
    getCountAllRecords,
    getObjectTypes,
    getResponseExecuteRecord,
    getErrorResponseExecuteRecord,
    errorExportAllData,
    exportAllData
  } = useSelector((store) => store.monithorReducer);

  const {
    getEnterpriseByIDResponse,
  } = useSelector((store) => store.enterpriseReducer);

  const validationSchema = Yup.object().shape({
    objectType: Yup.string(),
    email: Yup.string()
      .max(100)
      .email("El correo no posee el formato correcto.")
  });

  const { control, errors, watch, getValues, setValue, handleSubmit, formState, trigger } = useForm({
    defaultValues: {
      objectType: "",
      dateStart: "",
      dateEnd: "",
      email:""
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });

  useEffect(() => {
    if(getEnterpriseByIDResponse.monitoringService){
      setEnvironmentURL(getEnterpriseByIDResponse.monitoringService);
      setVersion(getEnterpriseByIDResponse.monitoringVersion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEnterpriseByIDResponse]);

  useEffect(() => {
    if(version && (environmentURL || fullURL)){
      setLoading(true);
      setUrlMonithorService(fullURL ? fullURL : environmentURL);
      dispatch(getObjectTypeAction({
        version: version
      }));
      dispatch({
        type: GET_ALL_DATA_MONITHOR,
        payload: {data: [], state: true},
      });
      dispatch({
        type: GET_COUNT_ALL_RECORDS,
        payload: 0,
      });

      if (objectTypeParam !== "") {
        dispatch(
          getCountAllRecordsAction({ 
            find: "", 
            type: `&${objectTypeParam}`, 
            version: version,
            startDate: getValues().dateStart,
            endDate: getValues().dateEnd
          })
        );
        dispatch(
          getAllRecords({
            find: "",
            rowPerPage: 10,
            page: 0,
            type: `&${objectTypeParam}`,
            version: version,
            startDate: getValues().dateStart,
            endDate: getValues().dateEnd
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, environmentURL, fullURL]);

  useEffect(() => {
    //if (getCountAllRecords !== 0) {
      setTotalRows(getCountAllRecords);
    //}
  }, [getCountAllRecords]);

  useEffect(() => {
    if (getAllData.state) {
      setLoading(false);
      setDatatable(getAllData.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllData]);

  useEffect(() => {
    if (getErrorAllData) {
      informMessage(getErrorAllData);
      setLoading(false);
      onOpen();
      dispatch({
        type: GET_ERROR_ALL_DATA_MONITHOR,
        payload: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getErrorAllData]);

  useEffect(() => {
    if (getResponseRetry) {
      informMessage(getResponseRetry);
      onOpen();
      dispatch({
        type: GET_RESPONSE_RETRY,
        payload: ""
      });
      setLoading(false);
      dispatch(
        getAllRecords({
          find: search,
          rowPerPage: rowsPerPage,
          page: rowsPerPage * page,
          type: `&${objectTypeParam}`,
          version: version,
          startDate: getValues().dateStart,
          endDate: getValues().dateEnd
        })
      );
    }
    if (getErrorResponseRetry) {
      informMessage(getErrorResponseRetry);
      onOpen();
      dispatch({
        type: GET_ERROR_RESPONSE_RETRY,
        payload: ""
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResponseRetry, getErrorResponseRetry]);

  useEffect(() => {
    if (getResponseExecuteRecord) {
      informMessage(getResponseExecuteRecord);
      onOpen();
      dispatch({
        type: GET_RESPONSE_CHANGE_STATUS,
        payload: ""
      });
      setLoading(false);
      dispatch(
        getAllRecords({
          find: search,
          rowPerPage: rowsPerPage,
          page: rowsPerPage * page,
          type: `&${objectTypeParam}`,
          version: version,
          startDate: getValues().dateStart,
          endDate: getValues().dateEnd
        })
      );
    }
    if (getErrorResponseExecuteRecord) {
      informMessage(getErrorResponseExecuteRecord);
      onOpen();
      dispatch({
        type: GET_ERROR_RESPONSE_CHANGE_STATUS,
        payload: ""
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResponseExecuteRecord, getErrorResponseExecuteRecord]);

  useEffect(() => {
    if (exportAllData) {
      informMessage(exportAllData);
      onOpen();
      dispatch({
        type: EXPORT_ALL_DATA,
        payload: "",
      });
    }
    if (errorExportAllData) {
      informMessage(errorExportAllData);
      onOpen();
      dispatch({
        type: ERROR_EXPORT_ALL_DATA,
        payload: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorExportAllData, exportAllData]);

  useEffect(() => {
    const objectTypeSelected = watch("objectType");
    if (objectTypeSelected !== "") {
      setLoading(true);
      dispatch(
        getCountAllRecordsAction({
          find: search,
          type: `&objectType=${objectTypeSelected}`,
          version: version,
          startDate: getValues().dateStart,
          endDate: getValues().dateEnd
        })
      );
      dispatch(
        getAllRecords({
          find: search,
          rowPerPage: rowsPerPage,
          page: 0,
          type: `&objectType=${objectTypeSelected}`,
          version: version,
          startDate: getValues().dateStart,
          endDate: getValues().dateEnd
        })
      );
      setObjectTypeParam(`objectType=${objectTypeSelected}`);
      setValue("dateStart", "");
      setValue("dateEnd", "");
      setSearch("");
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("objectType")]);

  useEffect(() => {
    if (getObjectTypes.state) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getObjectTypes]);

  useEffect(() => {
    if (isLoading) {
      sleep(4000).then(r =>{
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /* #endregion */

  /* #region  Config Table */
  const columns = [];

  switch (version) {
    case 2:
      columns.push(
        {
          id: "actions",
          label: "",
          haveDropDown: false,
          minWidth: 90,
          align: "left",
          childrenHeader: () => {
            return (
              <Grid container>
                <Grid item lg={4}>
                  <Tooltip title="Reintentar selecionadas">
                    <SvgIcon className={classes.IconHeader} 
                      onClick={() => {
                        if(datatable.filter(row => row.selected).length)
                          confirmMessage("Reintentar las transacciones selecionadas", action.SELECTED_RETRY);
                        else{
                          informMessage("No se ha seleccionado ninguna transacción");
                          onOpen();
                        }
                      }}
                      component={RetryIcon}
                    />
                  </Tooltip>
                </Grid>
                <Grid item lg={4}>
                  <Tooltip title="Omitir selecionadas">
                    <SvgIcon className={classes.IconHeader} 
                      onClick={() => {
                        if(datatable.filter(row => row.selected).length)
                          confirmMessage("Omitir las transacciones selecionadas", action.SELECTED_CHANGE_STATE);
                        else{
                          informMessage("No se ha seleccionado ninguna transacción");
                          onOpen();
                        }
                      }}
                      component={SkipIcon}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            );
          },
          children: (_, row) => {
            row.NotSelectionable = !(row.status_id === 2 && row.code_error_http !== "200");
            return (
              <Grid container style={{float:"right"}}>
                {row.status_id === 2 && row.code_error_http !== "200" &&
                (<Grid item lg={4}>
                  <Tooltip title="Reintentar">
                    <SvgIcon className={classes.Icon} 
                      onClick={() => {
                        confirmMessage("Reintentar la transacción", action.RETRY, row);
                      }}
                      component={RetryIcon}
                    />
                  </Tooltip>
                </Grid>)}
                {row.status_id === 2 && row.code_error_http !== "200" &&
                (<Grid item lg={4}>
                  <Tooltip title="Omitir">
                    <SvgIcon className={classes.Icon} 
                      onClick={() => {
                        confirmMessage("Omitir la transacción", action.CHANGE_STATE, row);
                      }}
                      component={SkipIcon}
                    />
                  </Tooltip>
                </Grid>)}
              </Grid>
            );
          },
        },
        {
          id: "transaction_id",
          label: "ID Transacción",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 100,
          key: " ",
          align: "left",
        },
        {
          id: "object_id",
          label: "ID Objecto",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 100,
          key: " ",
          align: "left",
        },
        {
          id: "object_type",
          label: "Tipo",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 100,
          key: " ",
          align: "left",
        },
        {
          id: "company_name",
          label: "Mandante",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 100,
          key: " ",
          align: "left",
        },
        {
          id: "status_name",
          label: "Estado",
          haveDropDown: true,
          align: "center",
          minWidth: 100,
        },
        {
          id: "message",
          label: "Mensaje",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 250,
          key: " ",
          align: "left",
        },
        {
          id: "last_updated_time",
          label: "Última ejecución",
          minWidth: 160,
          align: "center",
          haveDropDown: true,
          format: (value) => {
            return `${value}`;
          },
        },
      );
      break;
    default:
      columns.push(
        {
          id: "actions",
          label: "",
          haveDropDown: false,
          minWidth: 90,
          align: "left",
          childrenHeader: () => {
            return (
              <Grid container>
                <Grid item lg={4}>
                  <Tooltip title="Reintentar selecionadas">
                    <SvgIcon className={classes.IconHeader} 
                      onClick={() => {
                        if(datatable.filter(row => row.selected).length)
                          confirmMessage("Reintentar las transacciones selecionadas", action.SELECTED_RETRY);
                        else{
                          informMessage("No se ha seleccionado ninguna transacción");
                          onOpen();
                        }
                      }}
                      component={RetryIcon}
                    />
                  </Tooltip>
                </Grid>
                {/* <Grid item lg={4}>
                  <Tooltip title="Omitir selecionadas">
                    <SvgIcon className={classes.IconHeader} 
                      onClick={() => {
                        if(datatable.filter(row => row.selected).length)
                          confirmMessage("Omitir las transacciones selecionadas", action.SELECTED_CHANGE_STATE);
                        else{
                          informMessage("No se ha seleccionado ninguna transacción");
                          onOpen();
                        }
                      }}
                      component={SkipIcon}
                    />
                  </Tooltip>
                </Grid> */}
              </Grid>
            );
          },
          children: (_, row) => {
            row.NotSelectionable = row.STATUS === "OMITIDA";
            return (
              <Grid container>
                {row.STATUS !== "OMITIDA" && 
                (<Grid item lg={4}>
                  <Tooltip title="Reintentar">
                    <SvgIcon className={classes.Icon} 
                      onClick={() => {
                        confirmMessage("Reintentar la transacción", action.RETRY, row);
                      }}
                      component={RetryIcon}
                    />
                  </Tooltip>
                </Grid>)}
                {/* {row.STATUS !== "OMITIDA" &&
                (<Grid item lg={4}>
                  <Tooltip title="Omitir">
                    <SvgIcon className={classes.Icon} 
                      onClick={() => {
                        confirmMessage("Omitir la transacción", action.CHANGE_STATE, row);
                      }}
                      component={SkipIcon}
                    />
                  </Tooltip>
                </Grid>)} */}
              </Grid>
            );
          }
        },
        {
          id: "ID",
          label: "ID Transacción",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 100,
          key: " ",
          align: "left",
        },
        {
          id: "STATUS",
          label: "Estado",
          haveDropDown: true,
          align: "center",
          minWidth: 100,
        },
        {
          id: "DESCRIPTION",
          label: "Mensaje",
          haveDropDown: true,
          wordLength: 100,
          minWidth: 250,
          key: " ",
          align: "left",
        },
        {
          id: "LAST_EXECUTION_DATE",
          label: "Última ejecución",
          minWidth: 160,
          align: "center",
          haveDropDown: true,
          format: (value) => {
            return `${value}`;
          },
        },
      );
      break;
  }

  const getButtons = () => {
    let button = {};
    return button;
  };
  /* #endregion */

  const handleClickButton = (actionCurrent, value) => {
    if(version === 1){
      if (actionCurrent === action.RETRY || actionCurrent === action.CHANGE_STATE) {
        if ((value.URL_RETRY && value.URL_RETRY !== "") || (value.url_origen && value.url_origen !== "") || actionCurrent === action.CHANGE_STATE) {
          setLoading(true);
          const data = {
            version: version,
            OBJECT_ID: value.OBJECT_ID,
            TASK_TYPE: value.TASK_TYPE,
            ACTION: actionCurrent === action.RETRY ? "RETRY" : actionCurrent === action.CHANGE_STATE ? "SKIPPED" : ""
          };
          dispatch(executeRecord(data, false));
        }
      }
      if (actionCurrent === action.SELECTED_RETRY || actionCurrent === action.SELECTED_CHANGE_STATE) {
        datatable.filter(row => row.selected).forEach((selectedValue)=>{
          if ((selectedValue.URL_RETRY && selectedValue.URL_RETRY !== "") || (selectedValue.url_origen && selectedValue.url_origen !== "" || actionCurrent === action.SELECTED_CHANGE_STATE)) {
            setLoading(true);
            const data = {
              version: version,
              OBJECT_ID: selectedValue.OBJECT_ID,
              TASK_TYPE: selectedValue.TASK_TYPE,
              ACTION: actionCurrent === action.SELECTED_RETRY ? "RETRY" : actionCurrent === action.SELECTED_CHANGE_STATE ? "SKIPPED" : ""
            };
            dispatch(executeRecord(data, false));
            document.getElementById(selectedValue.controlId).checked = false;
            selectedValue.selected = false;
          }
        });
      }
    }

    if(version === 2){
      if (actionCurrent === action.RETRY) {
        setLoading(true);
        const data = {
          id: `${value.id}`,
        };
        dispatch(executeRecord(data));
      }
      if (actionCurrent === action.SELECTED_RETRY) {
        datatable.filter(row => row.selected).forEach((selectedValue)=>{
          setLoading(true);
          const data = {
            id: `${selectedValue.id}`,
          };
          dispatch(executeRecord(data));
          });
      }
      if (actionCurrent === action.CHANGE_STATE) {
        setLoading(true);
        const data = {
          id: `${value.id}`,
          status: 3,
        };
        dispatch(updateStatus(data));
      }
      if (actionCurrent === action.SELECTED_CHANGE_STATE) {
        datatable.filter(row => row.selected).forEach((selectedValue)=>{
          setLoading(true);
          const data = {
            id: `${selectedValue.id}`,
            status: 3,
          };
          dispatch(updateStatus(data));
          document.getElementById(selectedValue.controlId).checked = false;
          selectedValue.selected = false;
        });
      }
    }

    if (actionCurrent === action.VIEW_DETAIL) {
      setCurrentRecord(value);
      setShowDetail(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    dispatch(
      getAllRecords({
        find: search,
        rowPerPage: rowsPerPage,
        page: rowsPerPage * newPage,
        type: `&${objectTypeParam}`,
        version: version,
        startDate: getValues().dateStart,
        endDate: getValues().dateEnd
      })
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    const rowPerPage = parseInt(event.target.value, 10);
    dispatch(
      getAllRecords({
        find: search,
        rowPerPage: rowPerPage,
        page: 0,
        type: `&${objectTypeParam}`,
        version: version,
        startDate: getValues().dateStart,
        endDate: getValues().dateEnd
      })
    );
    setRowsPerPage(rowPerPage);
    setPage(0);
  };

  const handleSearch = (e) => {
    setLoading(true);
    setPage(0);
    dispatch(
      getCountAllRecordsAction({
        find: search,
        type: `&${objectTypeParam}`,
        version: version,
        startDate: getValues().dateStart,
        endDate: getValues().dateEnd
      })
    );
    dispatch(
      getAllRecords({
        find: search,
        rowPerPage: rowsPerPage,
        page: 0,
        type: `&${objectTypeParam}`,
        version: version,
        startDate: getValues().dateStart,
        endDate: getValues().dateEnd
      })
    );
  }

  const handleExport = async (e) => {
    if(!user?.email && !getValues().email){
      informMessage("Por favor registrar el correo a enviar");
      onOpen();
      return;
    }
    await trigger();
    if (isEmpty(formState.errors)) {
      if(totalRows === 0){
        informMessage("No hay registros a exportar");
        onOpen();
        return;
      }
      setLoading(true);
      setPage(0);
      dispatch(
        exportAllRecords({
          find: search,
          type: `&${objectTypeParam}`,
          version: version,
          startDate: getValues().dateStart,
          endDate: getValues().dateEnd,
          email: user?.email ?? getValues().email
        })
      ); 
    }
  }

  return (
    <>
      <ViewLayout headerTitle={`Monithor ${getEnterpriseByIDResponse.name??""}`} visibleIcon={true}>
        {showDetail ? (
          <MonitoringDetailView
            setCurrentRecord={setCurrentRecord}
            setShowDetail={setShowDetail}
            currentRecord={currentRecord}
            enterprise={getEnterpriseByIDResponse}
          />
        ) : (
          <Grid container>
            <Grid item container md={10} sm={12} xs={11}>
              {!params.get("type") && getObjectTypes.data?.length > 0 && (
                <Grid item sm={4} xs={12} style={{ marginRight: "10px"}}>
                  <ControlledInputRoundedForm
                    id="objectType"
                    name="objectType"
                    label="Integración"
                    control={control}
                    fullWidth
                    select
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    error={errors.objectType}
                    helperText={errors.objectType?.message}
                  >
                    {getObjectTypes.data.map((option) => (
                      <MenuItem
                        className={classes.muiList}
                        key={option.id}
                        value={option.name}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </ControlledInputRoundedForm>
                </Grid>
              )}
              <Grid item container spacing={1} sm={6} xs={12}>
                <Grid item md={6} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="dateStart"
                    name="dateStart"
                    label="Fecha inicio"
                    control={control}
                    fullWidth
                    type="date"
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    error={errors.dateStart}
                    shrink={true}
                    helperText={errors.dateStart?.message}
                  ></ControlledInputRoundedForm>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <ControlledInputRoundedForm
                    id="dateEnd"
                    name="dateEnd"
                    label="Fecha fin"
                    control={control}
                    fullWidth
                    type="date"
                    shrink={true}
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                    error={errors.dateEnd}
                    helperText={errors.dateEnd?.message}
                  ></ControlledInputRoundedForm>
                </Grid>
              </Grid>
              {(objectTypeParam !== "" || version === 2) && (
                <Grid item sm={7} xs={12}>
                  <InputSearch
                    name="CustomersFilter"
                    value={search}
                    inputRef={searchRef}
                    onChange={(e) => {
                      setSearch(searchRef.current.value);
                    }}
                    onKeyDown={(event)=>{
                      if (event.key === 'Enter') {
                        handleSearch(event);
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search className={classes.colorSearchAdornment} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    style={{ width: "104%" }}
                  />
                </Grid>
              )}
              {(objectTypeParam !== "" || version === 2) && (
                <Grid
                  item container spacing={2}
                  sm={4}
                  xs={12}
                  style={{ alignSelf: "center", flexFlow: "row", paddingLeft: "20px"}}
                >
                    <IconEspecialButton
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      size={"small"}
                      onClick={handleSearch}
                      startIcon={<Search />}
                    >
                      Buscar
                    </IconEspecialButton>
                    {user?.email ? (
                      <IconEspecialButton
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        size={"small"}
                        onClick={handleExport}
                        startIcon={<Launch />}
                      >
                        Exportar
                      </IconEspecialButton>
                    ) : (
                      <div style={{alignSelf: "center", display: "flex", paddingLeft:"10px"}}>
                        <CustomCheckbox 
                          id={"chk_export"}
                          name={"chk_export"}
                          onChangeHandler={(e, checked)=>{
                            setCustomEmail(checked);
                          }}
                        />
                        <label style={{paddingLeft:"5px"}}>Exportar</label>
                      </div>
                    )}
                </Grid>
              )}
              {!user?.email && customEmail &&(
                <Grid item container spacing={2}
                  sm={12} xs={12} 
                >
                  <Grid item
                    sm={7} xs={12}
                    style={{ alignSelf: "center", flexFlow: "row", paddingLeft: "20px"}}
                  >
                    <ControlledInputRoundedForm
                      id="email"
                      name="email"
                      label="Correo"
                      control={control}
                      fullWidth
                      pattern={/[^a-zA-Z0-9._\-@+]/g}
                      style={{ marginLeft: "10px", marginTop: "10px" }}
                      error={errors.email}
                      shrink={true}
                      helperText={errors.email?.message}
                    ></ControlledInputRoundedForm>
                  </Grid>
                  <Grid item
                    sm={1} xs={1}
                    style={{ alignSelf: "center", flexFlow: "row", paddingLeft: "20px"}}
                  >
                    <IconEspecialButton
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="contained"
                      size={"small"}
                      onClick={(e)=>{
                        handleSubmit(handleExport(e));
                      }}
                      startIcon={<Launch />}
                    >
                      Enviar
                    </IconEspecialButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <CustomTable
              columns={columns}
              mainParam={"name"}
              data={datatable}
              buttons={getButtons()}
              option={option}
              setOption={setOption}
              handleCellClick={(e, row)=>{handleClickButton(action.VIEW_DETAIL,row)}}
              paramToDisable={"idstate"}
              havePagination={false}
            />
            {totalRows > 0 && (
              <Grid>
                <TablePagination
                  component="div"
                  count={totalRows}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 30]}
                />
              </Grid>
            )}       
          </Grid>
        )}
      </ViewLayout>
      <FullLoader open={isLoading} viewLoader={true} />
      <Dialog />
    </>
  );
};

export default MonitoringView;